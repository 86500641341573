:root {
  --active: #f5f5f5;
  --black: 'black';
}

.text-xs {
  font-size: 0.5rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-md {
  font-size: 1rem !important;
}

.text-lg {
  font-size: 1.25rem !important;
}

.text-xl {
  font-size: 1.5rem !important;
}

.text-2xl {
  font-size: 1.75rem !important;
}

.text-3xl {
  font-size: 2rem !important;
}

.text-4xl {
  font-size: 2.5rem !important;
}

.list-columns-2 {
  columns: 2 !important;
}

.list-columns-3 {
  columns: 3 !important;
}

.list-columns-4 {
  columns: 4 !important;
}

.bg-active {
  background-color: var(--active) !important;
}

/* ======= Inspina overrides ======== */
body.mini-navbar .nav-header {
  background-color: transparent !important;
}

span.link { text-decoration: underline; cursor: pointer;}

.search-container {
  position: relative;
  margin-top: 10px;
}